import React from "react";
import AppRoutes from "./routes/Routes";
import { hotjar } from 'react-hotjar';
import { HelmetProvider } from 'react-helmet-async';
import PiwikPro from '@piwikpro/react-piwik-pro';
PiwikPro.initialize('a4af65d6-c0e2-4447-b0f9-8b23693b74e5', 'https://pantheon-project.containers.piwik.pro');

hotjar.initialize('3530198', '6');

// Identify the user
hotjar.identify('USER_ID', { userProperty: 'value' });

// Add an event
hotjar.event('button-click');

// Update SPA state
hotjar.stateChange('https://pantheon-project.eu/');

// Check if Hotjar has been initialized before calling its methods
if (hotjar.initialized()) {
  hotjar.identify('USER_ID', { userProperty: 'value' });
}
const App = () => {
  return (
    <div>
       <HelmetProvider>
         <AppRoutes />
      </HelmetProvider>
    </div>
  );
};

export default App;
