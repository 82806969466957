import React, {useState} from "react";
import FeatureImg from "../components/features/FeatureImg";
import FeatureImgThree from "../components/features/FeatureImgThree";
import FeatureImgTwo from "../components/features/FeatureImgTwo";
import Features from "../components/features/Features";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import AllReview from "../components/testimonial/AllReview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import Epsilon from '../plogos/EPSILON_MT_INT.jpg'
import Enac from '../plogos/ENAC_logo.png'
import CMSA from '../plogos/LogoCMSA.png'
import FINT from '../plogos/fint-logo-final.svg'
import twi from '../logos/twi.png'
import INTEROPTICS from '../plogos/interoptics_logo.png'
import ispc from '../plogos/ISPC_Logo.jpg'
import JOAFG from '../plogos/JOAFG_Logo_Farbe_sRGB.jpg'
import ISEMI from '../plogos/ISEMI_claim_big.jpg'
import HP from '../plogos/Hellenic Police logo.png'
import KEMEA from '../plogos/kemea_logo.jpg'
import M3Systems from '../plogos/M3SB_logo_horizontal.png'
import UPC from '../plogos/UPC_logo.png'
import simavi from '../plogos/simavi.jpg'
import practin from '../plogos/Practin-Logo.png'
import mion from '../plogos/MION logo.png'
import Airbus from '../plogos/AIRBUS DEFENCE _ SPACE logo.png'


export default function Partners() {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [twiShow, settwiShow] = useState(false);
  const [M3SystemsShow, setM3SystemsShow] = useState(false);
  const [mionShow, setmionShow] = useState(false);
  const [practinShow, setpractinShow] = useState(false);
  const [simaviShow, setsimaviShow] = useState(false);
  const [upcShow, setupcShow] = useState(false);
  const [KEMEAShow, setKEMEAShow] = useState(false);
  const [ISEMIShow, setISEMIShow] = useState(false);
  const [HPShow, setHPShow] = useState(false);
  const [EpsilonShow, setEpsilonShow] = useState(false);
  const [ENACShow, setENACShow] = useState(false);
  const [CMSAShow, setCMSAShow] = useState(false);
  const [FINTShow, setFINTShow] = useState(false);
  const [INTEROPTICSShow, setINTEROPTICSShow] = useState(false);
  const [ispcShow, setispcShow] = useState(false);
  const [JOAFGShow, setJOAFGShow] = useState(false);
  const [AirbusShow, setAirbusShow] = useState(false);
  return (
    <Layout>
      <Navbar />
      <PageHeader HeaderTitle="Partners" Parent="Pages" PageTitle="Partners" />
      <section className="promo-section mt-5 ptb-100">
        
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <section class="section section-default mt-none mb-none">
<div class="container">

<br/>
<strong>
<div class="row">

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={twi} onClick={() => settwiShow(true)}/>
<Modal 
        show={twiShow}
        onHide={() => settwiShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>TWI Hellas
        <span> <a target="_blank" href="https://www.twi-hellas.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => settwiShow(false)}/>
        </Modal.Header>
        <Modal.Body>TWI Hellas is a wholly-owned Greek-based subsidiary of TWI, an independent research and technology organisation with physical offices in the UK, Europe, South East Asia, India, the Middle East, Central Asia and the USA. The team specialised in the fields of advanced robotics, artificial intelligence, cloud, and digital engineering, develops state-of-the-art technologies for volume-efficient data processing, model-driven data analysis, and large-scale data management to deliver:
</Modal.Body>
<Modal.Body>
<ul>
  <li>• State-of-the-art Machine Learning algorithms for spatiotemporal data processing (image, video) and analysis enabling step-changes in information extraction and machine awareness.</li>
  <br/>
  <li>• Next-generation Human Machine Interfaces delivered seamlessly through web/mobile platforms for sophisticated tasks, from data processing to special-purpose robot control.</li>
  <br/>
  <li>•	Digital simulation models (Digital Twins) that update with input from multiple sources and change as their physical counterparts change to represent real-time status or infer their condition.</li>
</ul>
</Modal.Body>
  <Modal.Body>To maximise the technological impact of collaborative R&D and industrial projects while boosting their smooth introduction to the market, TWI Hellas has assembled a dedicated team for market analysis, project management, dissemination and exploitation.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={Airbus} onClick={() => setAirbusShow(true)}/>
<Modal 
        show={AirbusShow}
        onHide={() => setAirbusShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Airbus
        <span> <a target="_blank" href="https://www.airbus.com/en"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setAirbusShow(false)}/>
        </Modal.Header>
        <Modal.Body>
        Airbus is a leader in designing, manufacturing and delivering aerospace products, services and solutions to customers on a worldwide scale. With around 130,000 employees and as the largest aeronautics and space company in Europe and a worldwide leader, Airbus is at the forefront of the aviation industry. We build the most innovative commercial aircraft and consistently capture about half of all commercial airliner orders. Thanks to our deep understanding of changing market needs, customer focus and technological innovation, we offer products that connect people and places via air and space.
</Modal.Body>
<Modal.Body>
Additionally, Airbus always has been at the forefront of innovating new technologies, with a pioneering spirit that has redefined the aerospace industry. Our products bring people closer together, helping them unite and progress. We strive to continually push the boundaries on what is possible to safeguard our world for future generations.
</Modal.Body>
      </Modal>
</div>
</div>  

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={UPC} onClick={() => setupcShow(true)}/>
<Modal 
        show={upcShow}
        onHide={() => setupcShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Technical University of Catalonia
        <span> <a target="_blank" href="https://www.upc.edu/en?set_language=en"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setupcShow(false)}/>
        </Modal.Header>
        <Modal.Body>Universitat Politècnica de Catalunya (UPC) is a public university for research and higher education in the fields of engineering, architecture, sciences and technology. Every year, more than 6,000 bachelor’s and master’s students graduate from UPC, and more than 200 become PhD. UPC is one of the leading technical universities in Europe, being in the last QS World University Ranking top-ranked in broad subject areas: Architecture, Civil Engineering, Telecommunication and Computer Science.
</Modal.Body>
<Modal.Body>The UPC generates knowledge and transfers it to the production sector to increase businesses' capacity for innovation and competitiveness. The University's corporate relations consist of high-value innovation services, such as research projects, the generation and exploitation of patents, the use of facilities and tailor-made training.</Modal.Body>
<Modal.Body>
UPC is a referent regarding participation in European-funded competitive projects. In the PANTHEON project, UPC has a leading role in research data management while providing their experience as drone operators and research background on unmanned aerial systems.
</Modal.Body>

      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={simavi} onClick={() => setsimaviShow(true)}/>
<Modal 
        show={simaviShow}
        onHide={() => setsimaviShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Software Imagination & Vision
        <span> <a target="_blank" href="https://www.simavi.ro/en"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setsimaviShow(false)}/>
        </Modal.Header>
        <Modal.Body>Software Imagination & Vision S.R.L. (SIMAVI) is a company of SIVECO Romania SA which inherited all the assets related to the software development activities, the related experience in the field,
the implementation teams, the certificates, and the authorisations held by SIVECO Romania SA. SIMAVI has also taken over software products and projects from the following fields of activity: R&D, Education & Training, eHealth, Security, Customised Applications and Government. SIMAVI has significant experience and an exceptional track record in R&D&I projects, being involved as a technological provider and a coordinator in many European and national research projects. Moreover, SIMAVI will continue the outstanding reputation of SIVECO, in international markets, by developing successful projects together with several international companies.

</Modal.Body>
<Modal.Body>
SIMAVI provides services on the whole life cycle of projects: analysis of user requirements, implementation, end-user training, technical assistance, and system maintenance. SIMAVI
staff consists of IT specialists covering all stages of projects development: analysts, business
consultants, system architects, programmers for different platforms, implementers, testers,
DBAs, data analysts, and accredited trainers. The solutions developed by SIMAVI are based on
successful and mature existing products and custom-made software operating on various
platforms and databases. They are web standard compatible and can be easily integrated with
other applications and internal or external databases (Geographical Information Systems,
database sharing, Business Intelligence Applications, etc.).
</Modal.Body>


      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={practin} onClick={() => setpractinShow(true)}/>
<Modal 
        show={practinShow}
        onHide={() => setpractinShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Practin
        <span> <a target="_blank" href="https://practin.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setpractinShow(false)}/>
        </Modal.Header>
        <Modal.Body>Practin offers a full range of services in managing and implementing projects, providing long-term support (operational, technical/user support, development), as well as developing complicated specific applications adjusted to the needs and specifications of the client or project. The company participates in all the phases of the development of a project, namely analysis of specifications, analysis and design of the software, development of it, writing documentation, installation and setup of the environment and its support. The knowhow and the tool utilised in development are based on or entirely consist of open source technologies.
</Modal.Body>
<Modal.Body>The company's main objective is to provide its customers with all the complex technological solutions for their business needs in the modern and demanding environment. It can develop a software product tailored to their clients' needs, business or activity. It can be designed and created from scratch to achieve their unique goals or use existing apps, desktop, web and cloud applications. Furthermore, it offers E-commerce solutions for shops of all shapes and sizes, data management, predictive analytics, and intelligent automation using Practin's in-depth knowledge of emerging technologies.</Modal.Body>
<Modal.Body>Practin will offer its expertise in the design and development of the project SW components, the
ML solutions for anomaly detection in traffic/activity of the IoT system, the self-adaptation and
reconfiguration of the HW and SW modules; Secure mobile device secure SW application and the Secure Rapid Development Kit (Sec-RDK).
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={mion} onClick={() => setmionShow(true)}/>
<Modal 
        show={mionShow}
        onHide={() => setmionShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>MION Technologies
        <span> <a target="_blank" href="https://miontechnologies.com/en/home/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setmionShow(false)}/>
        </Modal.Header>
        <Modal.Body>MION is a Spanish innovative SME that develops and commercialises instrumentation for security based on its own DMA (Differential Mobility Analyzer), a technology based on high-resolution ion filtering. The instruments are mainly EVDs (Explosive Vapor Detectors) for the detection of threats by their vapour emissions in extremely low concentrations under different scenarios like air cargo, maritime containers, people, or even parcels and not limited to explosives, also including chemical agents, bioweapons, drugs, etc.
</Modal.Body>
<Modal.Body>
MION, thanks to its experience in putting into the market new technologies for security, will lead the exploitation activities of the PANTHEON project by developing the market analysis and the business strategy. In addition, MION will have an active role in the dissemination and communication activities.</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={M3Systems} onClick={() => setM3SystemsShow(true)}/>
<Modal 
        show={M3SystemsShow}
        onHide={() => setM3SystemsShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>M3 Systems
        <span> <a target="_blank" href="https://M3Systems.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setM3SystemsShow(false)}/>
        </Modal.Header>
        <Modal.Body>M3 Systems Belgium (M3SB) is a private SME founded in 2004 in Brussels. M3 Systems
Belgium belongs to the MISTRAL group (based in Toulouse) with two sisters company; M3
Systems France and Boreal.
</Modal.Body>
<Modal.Body>
From the beginning, M3SB has specialised in Radio-navigation technologies (GNSS) and Air
Traffic Management (ATM). M3 Systems has reached a high level of expertise in GNSS and has
become well-recognized at the European level. In particular, M3 Systems has been involved since the early days of the Galileo signal definition through study contracts in support of the R&D activities of the French space agency.
</Modal.Body>
  <Modal.Body>M3SB competencies in ATM are renowned at the EU level, especially in the context of SESAR Joint Undertaking activities and through support to EUROCONTROL roadmap execution.
Thanks to its Boreal sister company (which manufactures a 4m wingspan UAV), M3 Systems
Belgium also bridges GNSS and ATM competencies through the development of
Unmanned Traffic Management (UTM) activities.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={KEMEA} onClick={() => setKEMEAShow(true)}/>
<Modal 
        show={KEMEAShow}
        onHide={() => setKEMEAShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}> Center for Security Studies
        <span> <a target="_blank" href="https://KEMEA.eu/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setKEMEAShow(false)}/>
        </Modal.Header>
        <Modal.Body>The Center for Security Studies (KEMEA), founded in 2005 by Law 3387, is a scientific, consulting and research organisation overseen by the Minister of Citizen Protection, governed by common law and annually audited by chartered accountants.  Its principal purpose is to conduct theoretical and applied research and studies, particularly at a strategic level, on security topics and policies.  Moreover, it is the think-tank of the Ministry of Citizen Protection on numerous policies such as public order, correctional services, terrorism prevention, crime prevention, integrated border management and civil protection, as well as on various other security and societal issues; it also provides advisory and consulting risk-management services to an array of public and private organisations.
        </Modal.Body>
        <Modal.Body>
        To meet the growing needs of the security and law enforcement practitioners and related academia, KEMEA has participated in research projects of the Horizon 2020 – the Research and Innovation Framework Programme of the European Commission – ever since it was launched;  part of the H2020 is devoted to “Secure Societies – Protecting freedom and security of Europe and its citizens”.  KEMEA’s objective, in this context, is not only the development of new technologies or the application of emerging technologies but also the understanding of associated phenomena and the development of more effective policies.
        </Modal.Body>
          <Modal.Body>Under this area of work within the EU and including the results of 2019, KEMEA currently ranks first in terms of both net EU contributions and the number of EU-funded projects (50 projects).   
        </Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={JOAFG} onClick={() => setJOAFGShow(true)}/>
<Modal 
        show={JOAFGShow}
        onHide={() => setJOAFGShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Johanniter Research and Innovation Center
        <span> <a target="_blank" href="https://www.johanniter.at/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setJOAFGShow(false)}/>
        </Modal.Header>
        <Modal.Body>Research and innovation at the intersection with practice - this is the common denominator of all Johanniter research projects. The Johanniter Research and Innovation Centre, based in Vienna, Austria, is dedicated to national and international projects in the fields of social welfare, health and care, and security and disaster management. The aim of the research is to develop and implement innovative solutions and new quality standards in order to optimise the quality of care and safety in these areas.
</Modal.Body>
<Modal.Body>
Based on our ideas and projects, we try to help our organisation continuously evolve in order to provide the best possible quality of care and service to our customers. This includes developing innovative approaches and establishing standards of quality. Both internal and external concerns and suggestions for improvement are welcomed.</Modal.Body>
<Modal.Body>Every idea is considered valuable and welcomed. The Johanniter Research and Innovation Center always sets itself the task of finding ways to implement new concepts and transform them into something bigger.</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={ispc} onClick={() => setispcShow(true)}/>
<Modal 
        show={ispcShow}
        onHide={() => setispcShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}> Public Security Institute of Catalonia
        <span> <a target="_blank" href="https://ispc.gencat.cat/en/inici/index.html"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setispcShow(false)}/>
        </Modal.Header>
        <Modal.Body>
        The Institute for Public Security of Catalonia (ISPC in its Catalan acronym) is the Higher
Education, Research, and Innovation Centre that provides training for the region’s police forces
and emergency services. As a leading public institution, ISPC operates to promote coordination
between the agents involved in Catalonia's particular security system, attending to the changing
needs of its society.
</Modal.Body>
<Modal.Body>
Since 2014, the Institute has also been a centre attached to the University of Barcelona to
become a benchmark in academic training, providing a Security Bachelor Degree and a Master's Degree.
</Modal.Body>
<Modal.Body>
ISPC is a referent regarding the creation and transfer of knowledge related to public safety and security through research, international cooperation, publications, and public events. Thanks to its experience, ISPC will have an active role in the PANTHEON project by providing an assessment of legal, ethical, and social aspects while also evaluating the proposed system in terms of usability and effectiveness.
</Modal.Body>

      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={ISEMI} onClick={() => setISEMIShow(true)}/>
<Modal 
        show={ISEMIShow}
        onHide={() => setISEMIShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}> International Security and Emergency Management Institute
        <span> <a target="_blank" href="https://www.isemi.sk/en_GB/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setISEMIShow(false)}/>
        </Modal.Header>
        <Modal.Body>The International Security and Emergency Management Institute (ISEMI) is a non-profit organisation providing publicly beneficial services registered within the MoI SR.
ISEMI is a professional platform of former or active police and national security officers, military, civil protection and crisis management experts from around the world with necessary security clearance and relevant long-term experience in crime prevention, counter-terrorism and CBRN-E threats in the fight against organised crime, environmental crime, trafficking in human beings, cybercrime, drugs and weapons smuggling. Our team also has a wide experience in intelligence analysis, defence policy, border protection, critical infrastructure protection, countering hybrid threats, rescue and emergency assistance, health protection and in the field of justice and prosecution.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={INTEROPTICS} onClick={() => setINTEROPTICSShow(true)}/>
<Modal 
        show={INTEROPTICSShow}
        onHide={() => setINTEROPTICSShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>INTEROPTICS
        <span> <a target="_blank" href="https://www.interoptics.gr/index.php/en/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setINTEROPTICSShow(false)}/>
        </Modal.Header>
        <Modal.Body>INTEROPTICS SA was founded in 1994 and is based in Athens, Greece. Our geographical market scope covers Greece & the Republic of Cyprus.
</Modal.Body>
<Modal.Body>Customer focus: Higher education institutions, secondary education schools, hospitals, medical societies, museums & archives, municipalities, government organisations, banks, corporations, etc.</Modal.Body>
<Modal.Body>
The company’s initial commercial focus was:
<li> Scientific database and journal
subscriptions</li>
<li> IT systems & services</li>
</Modal.Body>
<Modal.Body>Nowadays, the company's subscription department provides the following:
</Modal.Body>
<Modal.Body>
<li> Scientific database subscriptions</li>
<li> Print & electronic scientific journal
subscriptions</li>
<li> Print & electronic scientific books</li>
<li> Subscription-based library systems & tools</li>
<li> Anti-plagiarism and assessment tools</li>
<li> Document delivery service</li>

</Modal.Body>
<Modal.Body>The IT systems & services department provides and supports:
<li> E-Journal development</li>
<li> Scientific societies member management
systems</li>
<li> Web applications</li>
<li> Digital repositories & archival collections</li>
<li> Integrated library systems</li>
<li> Computer and network hardware</li>
<li> Distance learning systems</li>
</Modal.Body>
<Modal.Body>INTEROPTICS has ISO 9001:2015 and ISO
37001:2017 certifications.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={HP} onClick={() => setHPShow(true)}/>
<Modal 
        show={HPShow}
        onHide={() => setHPShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Hellenic Police
        <span> <a target="_blank" href="https://www.astynomia.gr/hellenic-police/?lang=en"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setHPShow(false)}/>
        </Modal.Header>
        <Modal.Body>The Hellenic Police assumed its present structure in 1984 when the Gendarmerie (Chorofylaki) and the Urban Police Forces (Astynomia Poleon) were merged (Law 1481/1-10-1984, Government Gazette 152 A). Hellenic Police (Elliniki Astynomia) is a Law Enforcement Agency according to the Law nr. 2800/2000, and its mission is to:
        </Modal.Body>
        <Modal.Body>
        <li>
ensure peace and order as well as citizens` unhindered social development, a mission that includes general policing duties and traffic safety
</li>
</Modal.Body>
        <Modal.Body>
<li>
prevent and interdict crime, as well as protect the State and the democratic form of government within the framework of constitutional order, a mission that also includes the implementation of public and state security policy
</li>
</Modal.Body>
<Modal.Body>Hellenic Police is comprised of both central and regional Services. Hellenic Police`s headquarters is the supreme authority over these Services. Its efforts centre on the fulfilment of the Force`s mission within the framework of the Ministry of Citizen’s Protection policy. For this reason, it schedules, directs, oversees and monitors the activities of its Services, and it ensures the necessary conditions for the exercise of its authority.
</Modal.Body>
<Modal.Body>In its continuous mission to serve and protect citizens, Hellenic Police has responded to our times` demands and challenges by improving training, adopting a modern crime prevention policy, better utilising science and technology and exploiting international law enforcement cooperation.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={FINT} onClick={() => setFINTShow(true)}/>
<Modal 
        show={FINTShow}
        onHide={() => setFINTShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Future Intelligence Ltd.
        <span> <a target="_blank" href="https://www.f-in.gr/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setFINTShow(false)}/>
        </Modal.Header>
        <Modal.Body>Future Intelligence (FINT) is a leading and highly innovative Group of Companies specialising in Information and Communication Technologies (ICT). The Group was initially established in Greece in 2009, is privately held, and its main facilities are located in Greece, the United Kingdom and Cyprus. FINT provides highly demanding solutions and business services covering a number of activities in the fields of long-range communications (WiMAX, LTE, 5G), short-range communications (WSN, M2M, IoT), and Future Internet (distributed cloud environments, artificial intelligence) and Aviation. 
</Modal.Body>
<Modal.Body>FINT is one of the leading IoT device manufacturers in Europe. The company's objective is to build a smart IoT-enabled ecosystem (FINoT Platform) which can expand horizontally and vertically, providing a playground for vendors, OEMs and software engineers. FINT will contribute to the development of the DSS and the SCDT model through its IoT environmental monitoring, AI and cloud processing expertise.</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={Epsilon} onClick={() => setEpsilonShow(true)}/>
<Modal 
        show={EpsilonShow}
        onHide={() => setFINTShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Epsilon
        <span> <a target="_blank" href="http://www.epsilon.gr/group/companies/epsilon-malta"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setEpsilonShow(false)}/>
        </Modal.Header>
        <Modal.Body>EPSILON Malta Ltd (EPSILON, Malta, www.epsilonmalta.com) is an independent Technology
Resources Engineering & Consulting company with expertise in Shipping, Aerospace,
Geoinformatics, Water & Environment, Energy, Regional Development, Homeland Security &
Natural Disasters, Civil Protection, and Economics and Management. EPSILON has a history since 1985 in Boston, USA, as established by its today, Shareholder. Today it operates worldwide as a legally fully independent company with Head Offices in Malta and supporting expertise for other Epsilon companies in GR, BG, CY, DE, IT and allied offices and companies in most European countries, with well-built links with foremost world technology organisations. EPSILON delivers engineering & consulting services, outsourcing & R&D commodities, training, and state-of-technology products such as geospatial database infrastructures and up-to-the-minute navigation & environment technologies. EPSILON shareholders and staff have carried expertise in RTD since 1990, with 100+ RTD Projects (FP3-to-HORIZON) with vast expertise in Business plans, Management of Projects, and marketing & Promotional Plans.

</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={Enac} onClick={() => setENACShow(true)}/>
<Modal 
        show={ENACShow}
        onHide={() => setENACShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>National School of Civil Aviation
        <span> <a target="_blank" href="https://www.enac.fr/en"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setENACShow(false)}/>
        </Modal.Header>
        <Modal.Body>ENAC is a public high school placed under the authority of the French Transport Ministry. ENAC trains engineers in electronics, computer and air transport. It also trains pilots, air traffic controllers and technical experts for maintenance and supervision. Today, ENAC has four academic laboratories: economics and econometrics, interactive computing and systems engineering, applied mathematics (probability, optimisation, operational research, optimal control, etc.), and telecommunications (GNSS navigation, communication and Electromagnetism). On top of academic research, ENAC is also focused on applied research through research programs like mini and micro RPAS. This program is split in three main themes: development of an OpenSource RPA System named Paparazzi (http://paparazzi.enac.fr), RPAS integration in the airspace and civil applications for small
RPAS. This project was born in 2003 from a will to build a cheap fixed wing autonomous MAV (Micro Air Vehicle). The UAV laboratory involves research into many fields: communication, navigation, surveillance systems, simulation, optimisation, air transport, system engineering, HMI, controls, applied mathematics and also traffic regulation.
 
</Modal.Body>
      </Modal>
</div>
</div>


<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={CMSA} onClick={() => setCMSAShow(true)}/>
<Modal 
        show={CMSAShow}
        onHide={() => setCMSAShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#6d2a00'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Crisis Management State Academy
        <span> <a target="_blank" href="https://cmsa.am/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#ef8948',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setCMSAShow(false)}/>
        </Modal.Header>
        <Modal.Body>The Crisis Management State Academy (CMSA) is an educational, scientific and
research institution of the Ministry of Emergency Situations for the development and adaptation
of guidelines, methods and approaches for first responders in cases of natural and man-made
disasters.
 
</Modal.Body>

<Modal.Body>
CMSA contribution
<li>Literature Review of Vulnerability and Community Capacity Assessment (VCA).</li>
<li>A special focus of the DRM system will be on vulnerable groups in the community, such as women, children, the elderly and people with disabilities, in order to raise awareness of the risks to all people and increase the capacity of the community as a whole</li>
<li>Creation of local communities resilient to natural disasters, with their involvement in all stages of disaster management and awareness of DRM mechanisms. </li>
<li>Training of community operators based on disaster scenarios.</li>
<li>Collection of data and knowledge created by the PANTHEON consortium, including open-access technical and scientific articles, academic articles, and their dissemination in a consistent and efficient manner.</li>

  </Modal.Body>
      </Modal>
</div>
</div>



</div>
</strong>
</div>
</section>
              </Col>
            </Row>
            </Container>
          
     
      </section>
      
      <Footer />
    </Layout>
  );
}
