import Footer from "../../components/layout/footer/Footer";
import AboutApp from "../../components/about/AboutApp";
import AccordionWithImg from "../../components/Faqs/AccordionWithImg";
import LatestNewsOne from "../../components/blogs/LatestNewsOne";
import Contact from "../../components/contact/Contact";
import FeatureImg from "../../components/features/FeatureImg";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Price from "../../components/prices/Price";
import PromoTwo from "../../components/promo/PromoTwo";
import VideoPromoTwo from "../../components/promo/VideoPromoTwo";
import TeamTwo from "../../components/team/Subscribe";
import HeroTwo from "./HeroTwo";
import Subsribe from "../../components/newsletter/Subsribe";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import Screenshot from "../../components/screenshot/Screenshot";
import FaqStatic from "../../components/Faqs/FaqStatic";
import FeatureImgThree from "../../components/features/FeatureImgThree";
import { Helmet } from 'react-helmet-async';



export default function HomeTwo() {
  return (
    <>
   
    <Layout>
      <Navbar  />
      <HeroTwo />
      <PromoTwo />
      <AboutApp />
  
     


     <VideoPromoTwo/>
      
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout></>
  );
}
