import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import ethics from "../../logos/photos/ethics.jpg"



export default function Ethics() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                The PANTHEON Ethics                                 </h3>
                <img
                  src={ethics}
                  style={{borderRadius:'10px',width:'400px',float:'right',marginLeft:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <p> Research involving human subjects, as the PANTHEON project does, raises important and
                    complex ethical issues that need to be addressed and integrated throughout the entire
                    project. Some of PANTHEON's research activities require the involvement of end-users,
                    stakeholders, and representatives of citizens in general and of specific social groups. These
                    activities are mainly interviews, questionnaires, focus groups and training <a href='https://pantheon-project.eu/June_came_with_2_PANTHEON_online_workshops'>workshops</a>. Ethical
                    issues arising from these activities and processes have been addressed during WP10 and have
                    been approved by the Ethics Committee of the <a href='https://www.upc.edu/en'>Universitat Politècnica de Catalunya (UPC)</a>, an
                    external and independent ethical assessment body.</p>
                   
      
                
                
             
  
       
                <p>From the outset, the PANTHEON consortium defined the ethical principles to be followed in
                    the development of the project based on international treaties and conventions on human
                    rights and the most relevant ethical guidelines. These principles are respect for human dignity,
                    integrity, voluntary participation, respect for individual autonomy, confidentiality and
                    anonymity, prevention of harm and prevention of misuse of experience. In addition, four main
                    areas were identified in relation to the research activities and processes in which ethical issues
                    could arise and which needed to be considered on an ongoing basis from the beginning of the
                    project.
                    </p>
                    <b style={{color:'#e95d04',fontSize:'16px'}}>Recruiting Participants</b>
                    <p style={{marginTop:'16px'}}>Firstly, the process of recruiting participants was defined. With regard to the criteria for
inclusion, the main guidelines were geographical location in relation to our pilot areas and
personal experience in our field of research, disaster risk reduction and management, although
attention was also paid to specific diversity criteria such as gender. In terms of reasons for
exclusion, it was decided to exclude people under 18 years of age, people over 75 years of age,
people who had not signed the informed consent form, people who had signed the consent
form but decided to withdraw it, and people who were unable to give consent. It is imperative
for our consortium that recruitment is the result of an unbiased and justified selection process
that minimises potential bias and is free from coercion and undue influence.</p>
  <b style={{color:'#e95d04',fontSize:'16px'}}>Obtaining Informed Consent</b>
  <p style={{marginTop:'16px'}}>Secondly, informed consent is an essential requirement for conducting research involving
human subjects. In the context of the PANTHEON project, activities involving the participation
of human subjects will only take place with the prior informed consent of the participants,
which will be obtained by signing a form specifically designed for the project. Before the
signature, the researchers responsible for each activity will ensure that all relevant information
about the project has been provided and that potential participants have understood it
correctly. In particular, they will inform participants how and why they were identified and
explain the inclusion and exclusion criteria for recruitment. They will also specify the aims,
objectives, methods, implications and main characteristics of the research activity, including
the source of funding. Particular emphasis will be placed on the voluntary nature of their
participation, explaining that they can withdraw from the activity at any time, thereby revoking
their consent. Finally, in all cases, they will be given the name and contact details of the person
responsible for dealing with their questions and any problems that may arise.</p>
  <b style={{color:'#e95d04',fontSize:'16px'}}>Collection, Storage and Processing of Personal Data</b>
  <p style={{marginTop:'16px'}}>Thirdly, it is vital to highlight the issue of collection, storage and processing of personal data.
The final product of PANTHEON - a community-based Smart City Digital Twin - is fundamentally
dependent on data so the PANTHEON project will process different categories of data,
including personal data. The latter is most abundant in the data extracted from expert
opinions that will be used to suggest requirements and recommendations for the design,
development and implementation of the PANTHEON system. Data such as spatial, geographic
and sensor data will also be collected to populate the digital twin, which will not contain
personal data. In strict compliance with the European Union's General Data Protection
Regulation (GDPR), each consortium partner is committed to fulfilling its responsibility to
collect and process personal data in an ethical and lawful manner.</p>
<p>On the one hand, all individuals whose personal data is processed by PANTHEON will be
informed of the purpose of the processing, the categories of data processed and the duration
of data storage. The identity and contact details of both the data controller and the data
protection officer (DPO) will also be provided. All interested parties shall be informed of their
rights in accordance with arts. 13 to 22 of the GDPR, in particular the right of access,
rectification and erasure of personal data, the right to withdraw consent at any time, the right
to object to and/or restrict data processing, the right to data portability and the right to lodge
a complaint with the competent supervisory authority.</p>
<p>On the other hand, researchers who work with personal data are committed to doing so
anonymously to protect the privacy of those involved. Interviews and focus groups will be
recorded and transcribed. Recordings, whether audio or video, will be kept securely by those
responsible for the research activity. The transcripts will be anonymised by those responsible
for each activity before being shared with third-party researchers. This will ensure that the
participant cannot be re-identified. The questionnaires will be conducted online, and all
personal data collected will be anonymised. It will not be possible to identify the respondents
at any time. After the training workshops, participants will be asked to complete an evaluation
questionnaire, which will be anonymous and, therefore, will not collect any personal data. All
results will be published anonymously and in aggregate form to protect the identity of all
participants.</p>
  <b style={{color:'#e95d04',fontSize:'16px'}}>Prevention of Misuse of Research and Results</b>
  <p style={{marginTop:'16px'}}>Fourthly, there is a need to prevent misuse of the research and/or its results. On the one hand,
in line with the above, there is the misuse of data collection. To prevent this, data
management protocols will be developed and implemented to ensure responsible and ethical
collection and processing of personal data. A secure data storage system will also be in place,
with personal data properly encrypted and protected by passwords that only authorised
personnel can access. On the other hand, the misuse of research results has also been
considered. Misinterpretation of data and/or results can lead to harmful consequences, such
as erroneous policy decisions that may harm individuals or communities. In any case, care
should be taken to ensure that results are published and disseminated as accurately and
responsibly as possible, avoiding exaggeration and misleading or inaccurate conclusions. In this
context, priority will be given to journals with a systematic and independent peer-review
process when selecting scientific journals for publication of the results.</p>
              </div>
            </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
