export const reviewData = [
  {
    id: 1,
    quote: "Geopolitical risk analysis brings together macroeconomic research and political assumptions in order to assess the impact of external parameters on investment decisions. It highlights the implications of erroneous assumptions on investor decisions. Our company experts, through conference calls and client meetings, provide real-time analysis of specific geopolitical themes or issues and estimate their impact on investments to be undertaken.",
    header:'Geopolitical Strategist'
  },
  {
    id: 2,
    quote:"We operate as a Research and Technological Development (RTD) hub reshaping the applied technologies ecosystem with the support of high level scientists and/or technology developers performing at world-class level. Through our network of excellent researchers and developers, we have earned become a significant resource for the progress of science and technology and the spreading of innovation in making our planet a better place to live and prosper. We are working with state-of-the-art knowledge providers bringing innovative ideas to investments on ICT, energy, electricity, food production, water management, housing, human resources assessment and development, health, and education.",
      header:'R&D Performer'
  },
  {
    id: 3,
    quote:"Perceptual differences correlated with management practices, data from customer interactions, social-media feedback, and the Internet of Things (IoT) are the tools for investment in developing and designing new products, reducing complexity, and boosting value. We are working with an international network of SME clusters and incubators that allow our clients to expand successfully in the international market arena. We understand that individual entrepreneurial inspiration can only be realized through the power of collaboration. Our team is an assembly of knowledgeable economists, accountants, business administrators, sociologists, psychologists, traders, engineers, architects, designers and artists. We speak many business languages and represent a full spectrum of possibilities, leading the way to intelligent and meaningful solutions for our clients.",
      header:'Business Consultant'
  },
  {
    id: 4,
    quote:
      "Our experts have a multicultural managerial background that allows transferring knowledge to clients for developing and running efficiently and effectively their SMEs, public administrations, and non-governmental organizations. The quintessential strength in our consulting is to create, transfer, share and apply management knowledge in successfully operating the client&#39;s business ecosystem. We assume the role of knowledge transfer based on study and practical experience, considerable knowledge of effective ways of acting in various management situations. We advise clients on how to stay away from general trends and to understand changes in the economic environment, to identify common causes of problems with a good chance of finding solutions, to see and seize new opportunities.",
      header:'Management Consultant'
  },
  {
    id: 5,
    quote:
      "Our legal entity specializes in the undertaking for collective investment (UCI) that entails portfolio management in line with the investment policy defined in the asset management rules and in the exclusive interest of shareholders, risk management, the administration and marketing of funds as well as other special activities related to the assets of funds. We support common investment funds (ECP) with no legal personality, carrying out its management by a venture company created for each case with specific asset management company adhering to well-defined rules and procedures.",
      header:'Investment Supporters'
  },
  {
    id: 6,
    quote:
      "We are experts on information and communications technologies (ICT) offering key technical solutions, influencing strategic decisions of private SMEs and public institutions. We provide tactical advisory services pertaining to business planning, product &amp; service policy definition, technical support, process reengineering, technology vendors selection. Our collaborations include business management and finance, transportation, oil &amp; gas operations, power &amp; utilities, integrated energy trading platforms.",
      header:'ICT Consultant'
  },
];

export const blogData = [
  {
    id: 1,
    category: "Lifstyle",
    thumbnail: "assets/img/blog/1.jpg",
    date: "Jan 21, 2019",
    comments: "45",
    share: "10",
    title: "Appropriately productize fully",
    excerpt:
      "Some quick example text to build on the card title and make up the bulk.",
  },
  {
    id: 2,
    category: "Technology",
    thumbnail: "assets/img/blog/2.jpg",
    date: "Jan 21, 2019",
    comments: "45",
    share: "10",
    title: "Quickly formulate backend",
    excerpt:
      "Synergistically engage effective ROI after customer directed partnerships",
  },
  {
    id: 3,
    category: "Science",
    thumbnail: "assets/img/blog/3.jpg",
    date: "April 26, 2019",
    comments: "41",
    share: "30",
    title: "Objectively extend extensive",
    excerpt:
      "Holisticly mesh open-source leadership rather than proactive users",
  },
  {
    id: 4,
    category: "Science",
    thumbnail: "assets/img/blog/4.jpg",
    date: "April 26, 2019",
    comments: "41",
    share: "30",
    title: "Objectively extend extensive",
    excerpt:
      "Holisticly mesh open-source leadership rather than proactive users",
  },
  {
    id: 5,
    category: "Science",
    thumbnail: "assets/img/blog/5.jpg",
    date: "April 26, 2019",
    comments: "41",
    share: "30",
    title: "Objectively extend extensive",
    excerpt:
      "Holisticly mesh open-source leadership rather than proactive users",
  },
  {
    id: 6,
    category: "Science",
    thumbnail: "assets/img/blog/6.jpg",
    date: "April 26, 2019",
    comments: "41",
    share: "30",
    title: "Objectively extend extensive",
    excerpt:
      "Holisticly mesh open-source leadership rather than proactive users",
  },
];
