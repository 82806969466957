import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import img1 from "../../logos/photos/dod.jpeg"
import MyPDF2 from "../../logos/photos/DOD2024.pdf"





export default function DOD() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON Project at DOD 2024
                                 </h3>
                <p>
                This summer was particularly interesting, with events like the <a href='https://pantheon-project.eu/PANTHEON%20Project%20at%20the%20ISPC%20Summer%20School'>13th ISPC Summer School</a> in
June, and it continued so with the <a href='https://www.ares-conference.eu/dod2024/'>International Workshop on Dynamics of Disasters (DOD):</a>
Hybrid Threats on July 30th and August 1st. The event was co-located with the 19th
International Conference on Availability, Reliability and Security (<a href='https://www.ares-conference.eu/'>ARES 2024</a>) at the <a href='https://www.univie.ac.at/en/'>University
of Vienna</a>, Austria.
</p>
<p>
Its focus was on Hybrid Threats and thus aligned beautifully with the PANTHEON project’s
scope and mission . Around 400 participants from all over the globe participated in the
workshop, hence providing fertile ground for the interaction of a diverse group of specialists
from all fields of disaster management and prevention, bringing together practical and
theoretical approaches to disaster resilience.</p>

<p>
More specifically, the PANTHEON project presentation outlined a short paper to be published
as part of this year’s conference proceedings. The topic of the presentation delved into the
potential impacts of hybrid threats and the benefits of utilising a smart city digital twin
environment on how those threats could be effectively managed and mitigated. 
</p>
<p>For more information, check out our presentation below:</p>
<a  id='newsletter' href={MyPDF2} download="DOD 2024.pdf">DOD 2024.pdf</a>


              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />

              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
