import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";
import { Helmet } from 'react-helmet-async';
import img1 from "../../logos/photos/ispc1.jpg"
import img2 from "../../logos/photos/ispc2.jpg"




export default function ISPC({ title = 'PANTHEON', description = 'sdsds', image = "https://pantheon-project.eu/static/media/ispc1.a3bff3be9f5df5dfc5e1.jpg", name = '3' }) {

  
  return (
    <>   <Helmet>
    { /* Standard metadata tags */ }
    <title>{title}</title>
    <meta name="image" property="og:image" content="https://pantheon-project.eu/static/media/ispc2.e825cbf449a6798bf8a2.jpg" data-rh="true"></meta>
    <meta name='description'  property="og:description"  content={description} />
   
</Helmet>
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON Project at the ISPC Summer School
                                 </h3>
                <p>
                This year, the <a href='https://ispc.gencat.cat/ca/formacio/formacio-oberta/13escoladestiu-seguretat-i-emergencia-climatica/'>13th Summer School</a>, organised by our PANTHEON partner, the 
               <a href='https://ispc.gencat.cat/en/inici/index.html'> Institute for Public Security of Catalonia (ISPC)</a>, took place on July 3rd and 4th. The ISPC, responsible for
training Catalonia's security forces, including local police, regional police, firefighters, civil
protection, and rural agents, ensures the summer school always has a practical, operational
focus. The first day was hosted at the ISPC premises, while the second one was held at the
<a href='https://www.macba.cat/en/'> Museum of Contemporary Art of Barcelona (MACBA)</a>.
</p>
<p>
Every year, the Summer School marks the end of the academic year for ISPC, with each year
focusing on a specific theme. This time, it consisted of keynote talks, dialogues, and roundtable
discussions aimed at enhancing knowledge on Security and Climate Emergency”. A poster
session was also introduced, covering a wide range of topics from national to European projects
concerning climate change, emergencies, and disaster management. In that context, Lola Maria
Dolors Valles and Kiril Shtefchyk Tatarchuk, ISPC members of our consortium, presented
PANTHEON, its mission and technologies.</p>
<p>Over the two days, topics such as energy transition, environmental management, disaster
management, and the psychological impact of climate change were covered. Overall, the goal
of the Summer School is to unite various operational bodies in Catalonia, along with
government officials, academics, and community members, to discuss mutual security
concerns, learn, and network.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={img2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />

              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout></>
  );
}
