import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import pantheon4 from "../../logos/photos/pantheon4.jpg"
import pantheon5 from "../../logos/photos/pantheon5.jpg"


export default function Vienna() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                Project PANTHEON travels to Vienna
                 </h3>
                <p>
                After our plenary meeting in Athens, Vienna hosted representatives of the consortium
                partners (<a  href='https://www.airbus.com/en'>Airbus</a>, <a  href='https://m3systems.eu/en/m3-systems-belgium/'>M3 Systems Belgium</a>, <a  href='https://www.simavi.ro/'>Software
                Imagination & Vision (SIMAVI)</a>, <a  href='https://miontechnologies.com/our-products/'>MION Technologies</a>, <a  href='https://www.f-in.gr/'>Future Intelligence</a>, <a  href='https://www.enac.fr/en'>Ecole Nationale de
                l'Aviation Civile</a>, <a  href='https://www.upc.edu/en'>Universitat Politecnica de Catalunya</a>, <a  href='https://practin.com/'>Practin</a>, <a  href='https://www.isemi.sk/en_GB/'>ISEM Institute</a>, <a  href='https://www.interoptics.gr/index.php/el/'>INTEROPTICS</a>,
                 <a  href='http://www.epsilon.gr/group/companies/epsilon-malta'>Epsilon Malta</a>, <a  href='https://ispc.gencat.cat/en/inici/index.html'>Institut de Seguretat Publica de Catalunya</a>, 
                 <a  href='https://kemea.gr/en/'>KEMEA</a>,
                <a  href='https://cmsa.am/'>Crisis Management State Academy</a>) for the second General Assembly on the 6th and 7th of July. The  <a  href='https://www.johanniter.at/'>Johanniter
                Research and Innovation Center (JOAFG)</a> picked up the two-day meeting organisation, so a
                big thank you is much deserved for their impeccable and smooth planning.</p>
                <p>During the first day of the assembly, the PANTHEON agenda included the overall
                presentation on the project progress in the first six months by <a href='https://www.twi-hellas.com/'>TWI Hellas</a>, the project
                coordinator, including finished and ongoing tasks, deliverables and the identification of risks.
                The partners with ongoing project work took up the stage, sharing details about the
                developments in specific tasks. On the second day, the consortium partners extensively
                discussed the pilot areas and all the prospective technical workshops. Last but not least,
                they concretised the PANTHEON plan for the next six months regarding system architecture
                and the definition of operational scenarios.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={pantheon4}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={pantheon5}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
