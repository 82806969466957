import React from "react";
import Counter from "../../components/counter/Counter";
import image1 from "../../logos/photos/pantheon12.png"

export default function HeroTwo() {
  return (
    <>
      <section
        className="hero-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/img1.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="hero-content-left text-white mt-5">
              <br/>
              <h1 className="text-white" style={{fontSize:'30px'}}>
                  <span> Building Tomorrow’s Community Disaster Resilience
</span> 
                </h1>
                <br/>  <br/>
                <h4><span  style={{color:'#ffffff', fontSize:'20px'}}> 
                Community-Based Smart City Digital Twin Platform for Optimised Disaster Risk Management operations and Enhanced Community Disaster Resilience
<img src={image1} style={{width:'0px',opacity:'0'}}></img>
                </span>
                </h4>
                
                <br/><br/><br/>

              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="hero-animation-img">
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
