import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import psce1 from "../../logos/photos/psce.png"




export default function PSCE() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON Project at the PSCE Spring Conference
                                 </h3>
                <p>
                The PANTHEON project will attend the upcoming <a href='https://www.psc-europe.eu/psce-conferences/psce-conference-in-vienna/'>Public Safety Communication Europe (PSCE)
Spring Conference</a> on 4-5 June 2024. Our partner, <a href='https://www.johanniter.at/'>Johanniter Research & Innovation Centre</a>,
will represent our Horizon Europe consortium and present our disaster management efforts to
strengthen Disaster Risk Management operations and enhance Community Disaster Resilience.
</p>
<p>
The PANTHEON project is focused on advancing public safety communications and emergency
response systems. Through innovative research and technology development, the project aims
to enhance the interoperability, resilience, and efficiency of communication networks used in
critical situations. By leveraging cutting-edge solutions, the PANTHEON project seeks to
improve the coordination and effectiveness of emergency response efforts, ultimately
contributing to the safety and security of communities.
</p>
<p>
Our PANTHEON research work and innovative technologies will be showcased through a
poster presentation and an elevator pitch on June 4th at the National Defence Academy in Sala
Terena in Vienna. We look forward to engaging with industry experts, practitioners, and fellow
researchers to exchange ideas and explore potential collaborations. Attending the PSCE
conference provides an excellent opportunity for us to contribute to the discourse on the future
of public safety communications and learn from other participants experiences and insights.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={psce1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
            
            
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
