import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import eurisy from "../../logos/photos/Eurisy.jpg"
import eurisy2 from "../../logos/photos/Eurisy2.png"



export default function Vienna() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                Introducing PANTHEON at the Eurisy’s Satellite-based Services for Disaster Risk Management 
                                 </h3>
                <p>
                <a href='https://www.eurisy.eu/'>Eurisy</a> is a non-profit association bringing together space agencies, 
                international organisations, research institutions, and private entities working or interested in space-related activities across Europe. 
                It organises a variety of events in order to promote progress through technological innovation, stir dialogue and build bridges between the public and private sectors.</p>
                <p>One of these efforts, the Satellite-based Services for Disaster Risk Management event, occurred in Slovakia. The Faculty of Informatics and Information Technology
                   of the <a href='https://www.stuba.sk/english.html?page_id=132'>Slovak University of Technology in Bratislava</a> hosted it on the 30th of November, 2023. There, our partner, the <a href='https://www.isemi.sk/en_GB/'>International Security and Emergency Management
                    Institute (ISEMI)</a>, took part in the event with Martin Kostolný, the Head of the Office. Part of his presentation was to introduce our PANTHEON project and, more specifically,
                    our mission to enhance CB-SCDT processes and establish optimal Disaster Risk Management operations by implementing satellite data, smart city IoT, insitu, community-generated 
                    data and AI technologies.

</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={eurisy2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={eurisy}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
             
            
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
