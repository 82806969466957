import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import img1 from "../../logos/photos/InterAigis_1.jpg"
import img2 from "../../logos/photos/InterAigis_2.jpg"
import img3 from "../../logos/photos/InterAigis_3.jpg"




export default function Integrais() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                Spreading the word about PANTHEON in Interaigis 2024
                                 </h3>
                <p>
                <a href='https://www.interaigis.gr/en/'>The International Civil Protection Fire Safety & Special Equipment Exhibition</a> for Local
Authorities (InterAigis 2024) is an annual event that brings together professionals and exhibitors
from the civil protection, fire safety, and special equipment sectors. The exhibition showcases
the latest technologies, equipment, and best practices in emergency response and disaster
management. Participants have the opportunity to attend seminars, demonstrations, and
networking events, which provide valuable insights and foster collaboration within the industry.
It's the ideal event for local authorities and professionals involved in civil protection and
emergency response.
</p>
<p>
The event lasted three days (April 11-13, 2024) and took place in the Exhibition Centre of the
Municipality of Peristeri. Our PANTHEON project participated in the event through our partner
<a href='https://kemea.gr/en/'>Centre for Security Studies (KEMEA)</a>, which will present our project and our efforts to bring
about greater disaster resilience and more structured disaster risk management practices.
</p>
<p>For a closer look at PANTHEON’s presence at the event, check below.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={img2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={img3}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
            
            
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
