import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import img1 from "../../logos/photos/Mapping.jpg"






export default function Mapping() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                Mapping the Future: Community-Based Disaster Management Strategies in Europe
                                 </h3>
                <p>
                In the context of the PANTHEON project, the “Analysis of CBDRM National and Regional Policies, Existing
Platforms and Uptakes” was conducted at the beginning of the project, including the mapping of the
disaster management and civil protection regime over the EU, as well as the identification and
description of platforms that facilitate decision-making and promote community-based disaster risk
management.
                </p>
                <p>In order to provide the reader with a holistic overview of the existing situation with regards to civil
protection strategies and plans, the study commences from a high-level international and pan-European
level and downscales to the national and regional level of the pilot areas of the projects, i.e.,
Greece/Attica Region, Austria/Vienna Province and formerly of France/ Île-de-France Region.</p>
<h5>Frameworks and Directives for International and EU Disaster Risk Management</h5>
                <p>As a first step, international disaster risk reduction and climate change adaptation strategies are
described, with the inclusion of the Sendai Framework and its targets and priorities for disaster impact
reduction and the United Nations International Strategy for Disaster Reduction. Focusing on the EU
level, the research centred around the structure and mandate of the Union Civil Protection Mechanism
and the European Programme for Critical Infrastructure Protection. In contrast, the European
Commission Directives related to the maintenance of the water environment, the management of flood
risk, the transportation of dangerous goods, the control of major accident hazards, the establishment of
the 112-emergency number, the increase of the level of cybersecurity and the level of security for
Critical Infrastructures around the EU are encompassed with direct correlation to their counterparts in
the national legislations of pilot countries.</p>
<h5>National Approaches to Civil Protection and Hazard Management</h5>
                <p>The second step is the description of civil protection strategies at the national level, which are
implemented to manage the major hazards that affect the areas under investigation. Thus, the authors
of this report describe the plans of the General Secretariat of Civil Protection of Greece for the
management of emergencies emerging from natural hazards, e.g., earthquakes, volcanic eruptions,

floods, snowfalls, wildfires, as well as from anthropogenic hazards, e.g., technological accidents, CBRNe
events, and transportation accidents. The same procedure was also followed for France, with the
description of the structure of civil protection in the country and the implemented plans for the
prevention of and response to natural and technological hazards, e.g., the natural risk prevention plan
(PPRN) and the technological risk prevention plan (PPRT), the flood prevention and the forest fire
prevention plan (PPRIF) among others. Due to the change in the pilot area from France to Austria and
specifically to Vienna, the respective information was added to the strategies and legislations valid for
the country, including, inter alia, the Austrian Security and Cybersecurity Strategies, the national crisis
and disaster plan and the strategy for adaptation to climate change.</p>
                <p>Finally, the strategies implemented at the local/regional level of the three countries are also identified.
In fact, these are derived from the respective national strategies but are adapted to the needs,
challenges, and hazards affecting the specific areas.</p>
<h5>Mapping Community Platforms for Disaster Decision-Making</h5>
                <p>Apart from the analysis of the disaster management regime, a mapping of platforms enabling and
facilitating decision-making while concurrently considering a community-based approach was
conducted. These platforms are either developed under the framework of EU research and innovation
projects or are produced by companies of the private sector (SMEs and industrial enterprises) and by
synergies of technical stakeholders with other stakeholders (research institutes or first responders).
However, the involvement of the community, ranging from vulnerable groups to the scientific
community, is the common denominator of the aforementioned projects.</p>
<p>Overall, this entry, along with the rest of our blog posts, are the foundations of the whole project as
they holistically describe the hazards, the implemented disaster management strategies and the
community vulnerability in the pilot areas, providing inputs to the technical partners of the Consortium
for the development of a Smart City Digital Twin technology, that is aligned with the disaster
management regime and the needs of the society.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                

              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
