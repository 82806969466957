import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import Subsribe from "../components/newsletter/Subsribe";
import PageHeader from "../components/team/PageHeader";
import Teams from "../components/team/Teams";
import BrandCarousel from "../components/testimonial/BrandCarousel";
import MyPDF1 from './pantheon-leaflet.pdf';
import MyPDF2 from './poster-pantheon.pdf';
import icon from './pdficon2.png'

export default function Outcomes() {
  return (
    <Layout>
      <Navbar />
      <PageHeader HeaderTitle="Outcomes" Parent="Pages" PageTitle="Outcomes" />
      <section className="promo-section mt-5 ptb-100" id='deliverables'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#ef8948'}}>
            Public Deliverables
                  </h3>
            </div>
          </div>
   
        </div>
      </section>
      <section className="promo-section mt-5 ptb-100" style={{backgroundColor:'whitesmoke'}} id='publications'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#ef8948'}}>
            Publications
                  </h3>
             
            </div>
         
          
          </div>
   
        </div>
      </section>
      <section className="promo-section mt-5 ptb-100" id='material'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#ef8948'}}>
            Promotional Material
                  </h3>
                  <ul style={{listStyle:'none'}}>
              <li style={{textAlign:'left',minWidth:'500PX'}}>
              <a style={{color:'#292929'}}id='newsletter' href={MyPDF1} download="Pantheon Leaflet.pdf">PANTHEON Leaflet <img style={{width:'22px',top:'8px',position:'relative'}} src={icon}></img></a>
              </li> 
    
              <li style={{textAlign:'left',minWidth:'500PX'}}>
              <a style={{color:'#292929'}} id='newsletter' href={MyPDF2} download="Pantheon Poster.pdf">PANTHEON Poster <img style={{width:'22px',top:'8px',position:'relative'}} src={icon}></img></a>
              </li> 
          </ul>
             
            </div>
         
          
          </div>
   
        </div>
      </section>

      <Footer space />
    </Layout>
  );
}
