import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import pantheon2 from "../../logos/photos/Rescuer.jpg"
import pantheon3 from "../../logos/photos/pantheon3.jpg"

export default function Exhibition() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON Project at the RESCUER workshop
                 </h3>
             <p>Between 20 and 21 February 2024, our PANTHEON project attended the post-pilot workshop of
the <a href='https://rescuerproject.eu/'>RESCUER project</a> in Aix-en-Provence, Southern France. Martin Kostolny, Head of the
Office at the <a href='https://www.isemi.sk/en_GB/o-nas/'>International Security and Emergency Management Institut (ISEMI)</a>, one of our
consortium members, represented it at the event.</p>
                <p>The event aimed to demonstrate the RESCUER system while spreading the word about the
project results. Additionally, it provided fertile ground for other projects and innovations to come
into the picture and present their solutions in the dedicated “Marketplace” area. Stakeholders
and experts from the field of first responders, emergency management, and disaster response
attended the two-day event. It featured a series of presentations and discussions that focused
on technologies for first responders and their deployment, as well as the challenges and
solutions on how end-users can be more actively involved in R&D projects.</p>
<p>A PANTHEON presentation was given at the event, showcasing our consortium’s efforts
towards building disaster-resilient communities. It focused on our project's approach to
community engagement, which involves working closely with local communities to identify their
needs, priorities, and concerns. We also highlighted the various tools and technologies we are
developing to support community resilience, including early warning systems, community-based
disaster risk reduction measures, and capacity-building programs.</p>
<p>Overall, the RESCUER workshop was a great success. It provided an excellent opportunity to
present our project's work and learn from other experts in the field. We look forward to

continuing our participation in such events and contributing to improving disaster risk
management.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={pantheon2}
                  style={{borderRadius:'10px',width:'500px'}}
                  alt="about us"
                  className="img-fluid"
                />
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
