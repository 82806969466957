import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import img1 from "../../logos/photos/Thessaloniki.png"
import img2 from "../../logos/photos/Fair_1.jpg"
import img3 from "../../logos/photos/Fair_2.jpg"




export default function Thessaloniki() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                The PANTHEON Project at the Thessaloniki International Fair 2023
                                 </h3>
                <p>
                The <a href='https://www.thessalonikifair.gr/en/87th-tif-has-concluded-enhanced-trade-and-business-section'>87th Thessaloniki International Fair (TIF)</a>, a unique platform that lasted for nine days,
September 9-17, 2023, hosted 1500 exhibitors, received 18 international participations, and had
Bulgaria as the Honoured Country with 60 enterprises. The fair was a blend of economic,
cultural, and sports events, making it a truly memorable experience.
</p>
<p>
Even though our PANTHEON project is still in its first year and early development phase, our
consortium member, <a href='https://kemea.gr/en/'>the Centre for Security Studies (KEMEA)</a>, had a stand at the fair.
Therefore, we seized the opportunity to showcase our project mission, technologies, and efforts
to intensify disaster risk management for the sake of local communities. Thankfully, the event
offered fertile ground for discussions around our project, and the public was interested in
learning more about our activities and the potential applications of our work.
</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={img2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={img3}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
            
            
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
