import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";
import pantheon14 from "../../logos/photos/pantheon14.jpg"
import pantheon5 from "../../logos/photos/pantheon5.jpg"
import {Helmet} from "react-helmet";

export default function Ethical() {

  
  return (
    <div>
  <Helmet>
{ /* Standard metadata tags */ }
<meta data-rh="true" property="og:image" content="http://pantheon-project.eu/static/media/pantheon14.405558083a16d46fcf7d.jpg"/>
      </Helmet>
    <Layout>
      
      <Navbar  />
       <p style={{marginTop:'16px'}}pageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON's approach to ethical technology development </h3>
                <img
                  src={pantheon14}
                  style={{borderRadius:'10px',width:'400px',float:'right',marginLeft:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <p style={{marginTop:'16px'}}>
                One of the main objectives of PANTHEON is to create a system to support key actors in
disaster risk management. The PANTHEON system will have two components, both focused on
the pre-disaster phase. Firstly, a training tool with the ability to react to pre-defined
parameters of a simulation, enabling users to take actions and view the outcome, thus
facilitating training in diverse and difficult-to-conduct scenarios. Secondly, it will include an
early warning and planning tool that will provide insights to optimise disaster planning and
resource allocation. The platform will use data from multiple sources such as satellites, sensors
and drones, as well as infrastructure, traffic and community data to populate the core
technology product: a Community-Based Smart City Digital Twin with visualisation and
simulation capabilities.</p>

                 <p style={{marginTop:'16px'}}>New technologies such as those to be used by PANTHEON cannot be separated from their
social context, as they will inevitably have some ethical and legal implications. Technologies
such as Artificial Intelligence or Smart Cities can come into conflict with fundamental rights
such as equality and non-discrimination through biased algorithms, digital divide or designs
that are insufficiently sensitive to human diversity. The risk of creating a digital twin that
inherently replicates these problems needs to be seriously considered.</p>
  <b style={{color:'#e95d04',fontSize:'16px'}}>Ethics in the Digital Age</b>
 <p style={{marginTop:'16px'}}>Security and privacy have also been affected by the digital age. There have already been cases
where fundamental human rights, such as freedom of expression, assembly and association,
have been curtailed in the name of security through censorship and hyperveillance. The threat
is particularly acute in smart cities that collect large amounts of data on their citizens and on
the use of unmanned aerial vehicles, which could seriously undermine privacy. In addition, the
implementation and use of new technologies, some of which do not always comply with
security standards, has led to new security challenges. For instance, the different layers that
make up the architecture of an IoT (Internet of Things) system are exposed to different
security issues that, if not properly addressed, could result in harm to individuals, including
personal data breaches.</p>
  <b style={{color:'#e95d04',fontSize:'16px'}}>Navigating Security and Privacy Challenges</b>
 <p style={{marginTop:'16px'}}>Ethical, legal and societal challenges of developing and deploying new technologies require
their architects to design them by incorporating measures that address the potential risks that
may arise. Ethics should be an integral part of the development of technological tools and
should be addressed from the earliest stages of their design, thus approaching the ethics by
design perspective. While achieving a useful tool for the end-user is a key objective, it is also
important that the tool is secure and respects privacy and other relevant human rights, the
latter being an important pillar for the social acceptability of the technology. The PANTHEON
project is therefore committed to ethical and technological development, which is recognised
as an important added value. To this end, the conception of the PANTHEON system idea has
taken into account the ethical, legal and social issues that may emerge during its design,
development and deployment and has consequently elaborated a strategy for dealing with
them.</p>
  <b style={{color:'#e95d04',fontSize:'16px'}}>The PANTHEON Ethical Vision</b>
 <p style={{marginTop:'16px'}}>The fundamental basis of our approach is to ensure that data complies with the CIA triad
(Confidentiality - Integrity - Availability). Confidentiality means ensuring that only authorised
and properly authenticated users have access to the data in the system. Integrity aims to
assure the quality and accuracy of the data and to prevent tampering, particularly with regard
to algorithms. Finally, availability refers to the access of authorised users to the data at any
time. To this end, the PANTHEON approach is based on the following key elements:</p>
<ul style={{listStyleType:'circle'}}>
  <li style={{marginBottom:'10px'}}>Security and privacy by design: the necessary measures to assure the security of the
PANTHEON system and its compliance with the EU General Data Protection Regulation
(GDPR) will be designed and implemented during the initial phases of system
development, as well as throughout its life cycle.</li>
<li style={{marginBottom:'10px'}}>Evaluation of the technology usage: this assessment will be carried out in three phases.
The first phase is the preparation of the evaluation process, consisting of screening,
scoping and planning. The second phase is the evaluation, which includes the systematic
description of results, impact assessment and recommendations. Finally, the third phase
focuses on post-evaluation, including consultation with the supervisory authorities and
continuous review.</li>
<li style={{marginBottom:'10px'}}>Risk and threat management: potential risks and threats will be identified and managed.
Their main characteristics, methods of attack, threat actors and vulnerability of system
components will be examined. This analysis will produce a risk matrix which will form the
basis for addressing potential threats and vulnerabilities of the system.</li>
<li style={{marginBottom:'10px'}}>Management measures: specific measures will be implemented to mitigate potential
biases in the data, including rigorous data pre-processing, use of diverse datasets, testing
of models before and after system deployment, adherence to the principles of
Trustworthy Artificial Intelligence, and compliance with the EU GDPR.</li>
<li style={{marginBottom:'10px'}}>Compliance testing: the technologies resulting from the PANTHEON project will be tested
against the main international standards, such as ISO/IEC 27000 on information security,
ISO 31700 on privacy by design and NIST SP 800-160 Vol. 2 Rev. 1 on the development of
cyber-resilient systems.</li>
</ul>
 <p style={{marginTop:'16px'}}>While it is impossible to predict all ethical issues that may emerge during the life cycle of
technological systems, this should not be an obstacle to devoting efforts to anticipate and
mitigate the already known risks and vulnerabilities. In this sense, the PANTHEON
consortium is committed to allocating all the resources at its disposal to monitor the
system from an ethical point of view and on an ongoing basis.</p>
              </div>
            </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
    </div>
  );
}
