import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import pantheon7 from "../../logos/photos/first_publication.png"


export default function Exhibition() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
              <h3 style={{color:'#e95d04'}}>
              Project PANTHEON welcomes its first publication!
             </h3>
             <p>On July 22nd, 2023, the article “Enhancing attack resilience of cyber-physical systems through
                state dependency graph models” was published in the <a href='https://www.springer.com/journal/10207'>International Journal of Information
                Security</a>. The publication was a collaborative effort between Konstantinos Adamos and George
                Stergiopoulos (<a  href='https://www.aegean.edu/'>University of the Aegean</a>), Michalis Karamousadakis (<a  href='https://www.twi-hellas.com/'>TWI Hellas</a>) and Dimitris
                Gritzalis (<a  href='https://www.aueb.gr/en'>Athens University of Economics and Business</a>).</p>
              <p>The paper focuses on a specific method that employs graph theory and state modelling
                algorithms to analyse the architecture of cyber-physical systems (CPS) automatically and thus
                provide cyber-physical systems risk assessment. This way, a tool arises that could deliver
                automatic decision support, improving the resilience of CPS architectures used in critical
                infrastructures.</p>
                <p>
                Check out the full paper below:
                <a href='https://link.springer.com/article/10.1007/s10207-023-00731-w'> Link</a>
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={pantheon7}
                  style={{borderRadius:'10px',width:'200px'}}
                  alt="about us"
                  className="img-fluid"
                />
               
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
