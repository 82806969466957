import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import ethics from "../../logos/photos/ethics.jpg"
import visual from "../../logos/photos/Visualisation.png"

import model from "../../logos/photos/Model.jpg"


export default function Model() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                Developing a PANTHEON Participatory Governance Model                                </h3>
                <img
                  src={model}
                  style={{borderRadius:'10px',width:'400px',float:'right',marginLeft:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <p> As part of PANTHEON's groundwork for disaster-resilient community approaches, a participatory
governance model has been developed to provide recommendations on approaches for effective and
inclusive community participation in all aspects of disaster risk management (DRM). The goal is to
inform, mobilise and organise communities, with a specific focus on vulnerable groups, to participate
in preparedness, response, and recovery.</p>
<b style={{color:'#e95d04',fontSize:'16px'}}>Exploring Decentralised and Participatory Governance</b>
      
<p style={{marginTop:'16px'}}>A comprehensive literature review was conducted covering key concepts such as participatory
governance, community, vulnerability, and resilience. The state-of-the-art decentralised and
participatory governance was analysed in detail, discussing the opportunities and challenges of
community-based disaster resilience based on academic discourse and practical evidence. Different
modes of community engagement were systematically explored, ranging from simply informing
communities to actively empowering them to take ownership. Specific considerations for engaging
diverse urban communities were also discussed, based on examples of participatory urban
governance initiatives.
                    </p>
                    <b style={{color:'#e95d04',fontSize:'16px'}}>Community Participation in DRM: Insights and Recommendations</b>
                    <p style={{marginTop:'16px'}}>The methodological approach rigorously combined this in-depth literature analysis with qualitative
empirical research. Two two-hour interactive workshops were organised with seven experts from
fields like emergency management, civil protection, and disaster research to gather on-the-ground
insights and recommendations. Additionally, a survey was conducted to further capture experiences
with community participation in DRM. The findings were thoroughly analysed using qualitative data
analysis. Together with insights from the literature, these findings were used to develop well-
grounded recommendations.</p>
<p>Key findings from the empirical research were insightfully structured along the process of community
engagement. This covered understanding the focus communities, establishing trusted relationships
by identifying facilitators and overcoming trust barriers, practically informing and mobilising
communities across disaster phases using context-specific tools, and diligently ensuring sustainable
impact through cultural awareness and inclusivity. Both facilitating factors and hindering barriers are
discussed in nuance.</p>
<p>Highly tailored recommendations, coherently structured according to the community engagement
process and disaster management phases, are presented as a result of the report. They cover vital

areas like two-way communication, relationship building, participatory vulnerability assessments,
inclusive citizen training programs, collaborative planning mechanisms, supporting grassroots
initiatives, and proactively engaging vulnerable groups.</p>
<img
                  src={visual}
                  style={{borderRadius:'10px',width:'500px',float:'left',marginRight:'20px',marginTop:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
<p>A comprehensive participatory governance model is proposed based on the findings encompassing
an iterative approach of community profiling, leveraging local networks, installing community liaison
officers, implementing tailored engagement initiatives, monitoring, evaluating, and adapting.
Ensuring the inclusivity of vulnerable groups was emphasised as a cross-cutting priority.</p>

  <p style={{marginTop:'16px'}}>In summary, this report rigorously develops a practical framework and actionable guidelines for
implementing inclusive community participation programs within disaster risk management,
specifically considering the context of the PANTHEON project. The evidence-based recommendations
can crucially guide efforts to actively engage all sections of communities, especially vulnerable
groups, across all aspects of disaster preparedness, response, and recovery.</p>
              </div>
            </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
