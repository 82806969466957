import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { LinkedInEmbed } from 'react-social-media-embed';


export default function SubscribeForm() {
  const url = "https://gmail.us14.list-manage.com/subscribe/post?u=ad86d50024dcd40cd824ab809&amp;id=c234b82d87&amp;f_id=00a6bae0f0";

const SimpleForm = () => <MailchimpSubscribe url={url}/>
  return (
    <>
      <section id="team" className="team-member-section">
        <div className="container">
        
            <div className="">
              
              <div className="section-heading text-center mb-5">
                

                <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (

      <div  id='simple' style={{padding:'40px',backgroundColor:'#ffede2',maxWidth:'400px',borderRadius:'20px',display:'inline-block'}}>
        <h5>Subscribe to our newsletter and stay updated.</h5>
        <SimpleForm  onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div style={{ color: "#1e646c",position:'absolute' }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" ,position:'absolute'}} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "#1e646c" }}>Subscribed !</div>}
      </div>
    )}
  />
              </div>
         

          </div>
        </div>
      </section>
    </>
  );
}
