import React from "react";

const Features = () => {
  return (
    <>
      <div className="overflow-hidden">
        <section style={{backgroundColor:'#292929'}} className="promo-section mt-5 ptb-100" id='solution'>
          <div className="container">
         

    
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  <h3 style={{color:'whitesmoke'}}>
                  The PANTHEON approach
                  </h3>
                <p style={{color:'whitesmoke'}}>The PANTHEON consortium will focus on developing a Community-based Digital Ecosystem for Disaster Resilience utilising Smart City Digital Twin (SCDT) technology and other emerging innovations to…
                </p>
             
                <ul className="list-unstyled" style={{color:'whitesmoke'}}>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">advance the existing risk assessment methods </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">mitigate potential vulnerabilities
</p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">build a strong community disaster resilience foundation</p>
                    </div>
                  </div>
                </li>
                </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="about-content-right">
                  <img
                    src="assets/img/img3.png"
                    style={{borderRadius:'20px'}}
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <p style={{color:'whitesmoke'}}>To achieve the above improvements, PANTHEON will provide the following:
</p>
<p style={{color:'whitesmoke'}}>A smart city digital twin environment entailing components suitable for simulations, training and evaluation of the behaviour of sub-systems, threats and the human factor.</p>
<p style={{color:'whitesmoke'}}>An early Detection and Situational awareness environment that can assist authorities with being alert about imminent incidents even in remote and hazardous locations.</p>
<p style={{color:'whitesmoke'}}>An integrated intelligent subsystem employing collaborative sensing from earth observations and drone technology.</p>
          </div>
          
        </section>
        
      </div>
    </>
  );
};

export default Features;
