import React from "react";
import { Routes, Route } from "react-router-dom";
import ChangePassword from "../components/others/ChangePassword";
import ComingSoon from "../components/others/ComingSoon";
import LoginTwo from "../components/others/LoginTwo";
import NotFound from "../components/others/NotFound";
import PasswordReset from "../components/others/PasswordReset";
import ThankYou from "../components/others/ThankYou";
import Login from "../pages/Login";
import HomeOne from "../themes/index-1/HomeOne";
import HomeTwo from "../themes/index-2/HomeTwo";
import HomeThree from "../themes/index-3/HomeThree";
import HomeFour from "../themes/index-4/HomeFour";
import HomeFive from "../themes/index-5/HomeFive";
import HomeSix from "../themes/index-6/HomeSix";
import HomeSeven from "../themes/index-7/HomeSeven";
import HomeEight from "../themes/index-8/HomeEight";
import News from "../pages/News";
import Outcomes from "../pages/Outcomes";
import TeamDetails from "../pages/TeamDetails";
import Download from "../pages/Download";
import Project from "../pages/Project";
import Faq from "../pages/Faq";
import SignupTwo from "../components/others/SignupTwo";
import SignupPage from "../pages/SignupPage";
import BlogGrid from "../pages/BlogGrid";
import BlogWithSidebar from "../pages/BlogWithSidebar";
import BlogDetails from "../pages/BlogDetails";
import ScrollToTop from "./ScrollToTop";
import Partners from "../pages/Partners";
import Kickoff from "../pages/blog/Kickoff";
import Exhibition from "../pages/blog/Exhibition";
import Workshops from "../pages/blog/Workshops";
import Vienna from "../pages/blog/Vienna";
import Publication from "../pages/blog/Publication";
import CivilProtection from "../pages/blog/CivilProtection";
import ViennaSecurity from "../pages/blog/ViennaSecurity";
import Eurisy from "../pages/blog/Eurisy";
import Ethics from "../pages/blog/Ethics";
import Malta from "../pages/blog/Malta";
import Ethical from "../pages/blog/Ethical";
import RESCUER from "../pages/blog/Rescuer";
import PSCE from "../pages/blog/PSCE";
import Model from "../pages/blog/Model";
import CERIS from "../pages/blog/CERIS";
import Integrais from "../pages/blog/Integrais";
import Thessaloniki from "../pages/blog/Thessaloniki";
import ISCRAM from "../pages/blog/ISCRAM";
import ISPC from "../pages/blog/ISPC";
import DOD from "../pages/blog/Dod";
import IDIMT from "../pages/blog/IDIMT";
import Mapping from "../pages/blog/Mapping";
import Conference from "../pages/blog/Conference";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeTwo />} />
      
        <Route path="/news" element={<News />} />
        <Route path="/outcomes" element={<Outcomes />} />
        <Route path="/project" element={<Project />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/blog-grid" element={<BlogGrid />} />
        <Route path="/blog-sidebar" element={<BlogWithSidebar />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/The_PANTHEON_project_kick-off_meeting" element={<Kickoff />} />
        <Route path="/PANTHEONs_debut_exhibition:_DEFEA_2023" element={<Exhibition />} />
        <Route path="/June_came_with_2_PANTHEON_online_workshops" element={<Workshops />} />
        <Route path="/Project_PANTHEON_travels_to_Vienna" element={<Vienna />} />
        <Route path="/Project_PANTHEON_welcomes_its_first_publication" element={<Publication />} />
        <Route path="/PANTHEON_joins_Civil_Protection_efforts_at_Safe_Attica_2023" element={<CivilProtection />} />
        <Route path="/PANTHEON_project_at_the_Vienna_Security_Festival_2023" element={<ViennaSecurity />} />
        <Route path="/Introducing_PANTHEON_at_the_Eurisy’s_Satellite-based_Services_for_Disaster_Risk_Management" element={<Eurisy />} />
        <Route path="/The 1st PANTHEON year comes full circle in Malta" element={<Malta />} />
        <Route path="/PANTHEONs approach to ethical technology development" element={<Ethical />} />
        <Route path="/The Pantheon Ethics" element={<Ethics />} />
        <Route path="/PANTHEON Project at the RESCUER workshop" element={<RESCUER />} />
        <Route path="/PANTHEON Project at the PSCE Spring Conference" element={<PSCE />} />
        <Route path="/Developing a PANTHEON Participatory Governance Model" element={<Model />} />
        <Route path="/PANTHEON travels to Brussels for the CERIS event 2024" element={<CERIS />} />
        <Route path="/Spreading the word about PANTHEON in Interaigis 2024" element={<Integrais />} />
        <Route path="/The PANTHEON Project at the Thessaloniki International Fair 2023" element={<Thessaloniki />} />
        <Route path="/Our PANTHEON Project at ISCRAM 2024" element={<ISCRAM />} />
        <Route path="/PANTHEON Project at the ISPC Summer School" element={<ISPC />} />
        <Route path="/PANTHEON Project at DOD 2024" element={<DOD />} />
        <Route path="/PANTHEON takes part in IDIMT Conference" element={<IDIMT />} />
        <Route path="/Mapping the Future: Community-Based Disaster Management Strategies in Europe" element={<Mapping />} />
        <Route path="/PANTHEON at the 27th Conference “Crisis Situations Solution in Specific Environment”" element={<Conference />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
