import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";
import { Helmet } from 'react-helmet-async';
import img1 from "../../logos/photos/Pantheon_RKS01.jpg"
import img2 from "../../logos/photos/Pantheon_RKS02.jpg"
import img3 from "../../logos/photos/Pantheon_RKS03.jpg"





export default function Conference({ title = 'PANTHEON', description = 'sdsds', image = "https://pantheon-project.eu/static/media/ispc1.a3bff3be9f5df5dfc5e1.jpg", name = '3' }) {

  
  return (
    <>   <Helmet>
    { /* Standard metadata tags */ }
    <title>{title}</title>
    <meta name="image" property="og:image" content="https://pantheon-project.eu/static/media/ispc2.e825cbf449a6798bf8a2.jpg" data-rh="true"></meta>
    <meta name='description'  property="og:description"  content={description} />
   
</Helmet>
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON at the 27th Conference “Crisis Situations Solution in Specific Environment”
                                 </h3>
                <p>
                The <a href='https://fbi.uniza.sk/en/aktuality/27th-conference-crisis-situations-solution-in-specific-environment'>27th International Scientific Conference on Crisis Situations Solution in Specific
                Environment</a> took place on October 16-17, 2024 in Žilina. Slovakia. The event was hosted by
the Faculty of Security Engineering of the University of Žilina. It aimed to explore new trends,
safety measures, and field experience in crisis management and public administration. It also
focused on community resilience and emergency management in challenging situations.
</p>
<p>
Spreading the PANTHEON word, representatives from <a href='https://www.isemi.sk/en_GB/'>the International Security and
Emergency Management Institute (ISEMI)</a> participated in the event and presented our project
mission, work and technologies. They informed the conference participants about the approach
that our project has adopted, a user-driven approach to modernising current <a href='https://pantheon-project.eu/Developing%20a%20PANTHEON%20Participatory%20Governance%20Model'>disaster resilience
model</a>s in combating both natural and man-made disasters. This approach sets the end-users
and related experts at the core of the development process and builds a community-based
digital ecosystem for disaster resilience. Last but not least, the project was part of the ISEMI
booth, so the conference attendees could also be informed through posters and leaflets.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={img1}
                  style={{borderRadius:'10px',width:'250px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={img2}
                  style={{borderRadius:'10px',width:'250px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={img3}
                  style={{borderRadius:'10px',width:'250px'}}
                  alt="about us"
                  className="img-fluid"
                />

              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout></>
  );
}
