import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import pantheon6 from "../../logos/photos/pantheon6.png"


export default function Workshops() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                June came with 2 PANTHEON online workshops
                 </h3>
                <p>
                The PANTHEON consortium had its first online workshops, one on the 8th and another on the
                28th of June. They were both organised by our project partner, the  <a  href='https://www.johanniter.at/'>Johanniter Research and
                Innovation Center</a>, and the focus was on community engagement/ participatory governance
                approaches in disaster management.</p>
                <p>More specifically, the workshops served the purposes of PANTHEON as an EU-funded
                initiative, aiming to generate differentiated input on how to engage, involve, mobilise and share
                valuable information with diverse communities regarding disaster preparedness, response and
                recovery. The participants of these workshops were professionals with experience in community
                engagement/participatory governance approaches and work in academia, local/town
                administration, First Responder Organizations/NGOs, and representatives of organisations
                working with vulnerable groups. Therefore, the goal for the participants of the workshops was to
                benefit from the mutual exchange of knowledge and expertise.</p>
                <p>The workshops were conducted online, in English and free of charge. Plus, there are more to
                come!</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={pantheon6}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
