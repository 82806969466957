import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import SubscribeForm from "../team/Subscribe";

export default function VideoPromoTwo() {
  const [isOpen, setOpen] = useState(false);


  return (
    <>
      <section
        id="download"
        className="video-promo ptb-100 background-img"
        style={{
          background:
            "url('assets/img/img1.jpg')no-repeat center center / cover",
        }}
      >
       <SubscribeForm/>
      </section>
    </>
  );
}
