import React from "react";
import FeatureImg from "../components/features/FeatureImg";
import FeatureImgThree from "../components/features/FeatureImgThree";
import FeatureImgTwo from "../components/features/FeatureImgTwo";
import Features from "../components/features/Features";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import AllReview from "../components/testimonial/AllReview";

export default function Project() {
  return (
    <Layout>
      <Navbar />
      <PageHeader HeaderTitle="Project" Parent="Pages" PageTitle="Project" />
      <section className="promo-section mt-5 ptb-100" id='problem'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            
            <h3 className="mb-6" style={{color:'#ef8948'}}>
            Current Challenges
               </h3>
            </div>
          </div>
          <p>In the past two decades, the international disasters database EM-DAT has recorded 7,348 natural disasters resulting in the loss of 1.23 million lives and affecting over 4 billion others. These environmental catastrophes have caused a total of €2.77 trillion in economic damages worldwide, without even including biological or technological disasters. 
          </p>

          <p>At present, the field of community resilience management is diverse yet fragmented, as current methods are not readily applicable or measurable. Collaboration for successful practical implementation of an ecosystem of tools in at-risk communities is not currently promoted, and systematic research and development of policies are stalled. 
          </p>
        </div>
      </section>
      <Features id='solution'/>
      <FeatureImg id='plan'/>
      <Footer />
    </Layout>
  );
}
