import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import pantheon8 from "../../logos/photos/CivilProtection.jpg"


export default function Vienna() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON joins Civil Protection efforts at Safe Attica 2023
                 </h3>
                <p>
                The 10th International Conference on Civil Protection and New Technologies, “Safe
                Attica”, was a fitting event for the presentation of the PANTHEON project. It took place
                between the 25th and 27th of September, 2023, at the conference centre of the War
                Museum in Athens, Greece and was addressed to academics, local governments and
                central administration, the private sector and voluntary organisations from Greece and
                abroad. The main focus of <a href='https://safegreece.org/safeattica2023/index.php/en/'>Safe Attica</a> is to identify how technology, interdisciplinary
                collaboration, and experience with previous emergencies could benefit Civil Protection.
                As expected, the existing challenges about Civil Protection were also brought to the
                table for discussion.</p>
                <p>Our partner, <a href='https://kemea.gr/en/'>the Center for Security Studies (KEMEA)</a>, actively participated in the event
                and presented the developments in the PANTHEON project so far. More specifically, on
                the 27th of September, Dr Ioanna Triantafyllou gave a presentation on the preliminary
                results of the PANTHEON initiative, especially addressing the major hazards affecting
                the Île-de-France and Attica regions as well as the relevant risk mitigation policies.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={pantheon8}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
