import React from "react";

export default function PromoTwo() {
  return (
    <>
      <section className="promo-section mt-5 ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
              <div className="section-heading mb-5">
              
                <h3 className="mb-6" style={{color:'#ef8948'}}>
                Introductory Note
</h3>
              </div>
             
            </div>
         
          
          </div>
          <p>The PANTHEON project joins the fight against both natural and man-made disasters by upgrading the existing disaster resilience models. Through the development of tools for evidence-based, policy-making analysis and evaluation of various issues and criteria, our mission is to build a community-based digital ecosystem for Disaster Resilience. Integral to this initiative is the implementation of Smart City Digital Twin (SCDT) technology which offers a more precise way to assess potential disasters and their consequences, supported by in-depth analysis of regional policies, models, and legal/regulatory environments. </p>
          <p>Disaster Risk Management of the future is possible, and the PANTHEON consortium will  work meticulously to provide communities with enhanced tools thanks to the availability of real-time data, thus improving decision-making efficiency during and after disasters.</p>
        </div>
      </section>
    </>
  );
}
