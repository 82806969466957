import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import pantheon4 from "../../logos/photos/Malta1.jpg"
import pantheon5 from "../../logos/photos/Malta2.jpg"


export default function Vienna() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                The 1st PANTHEON year comes full circle in Malta
                </h3>
                <p>
                A year ago, on January 18-19, 2023, the PANTHEON consortium, consisting of 17 partners (<a href='https://www.twi-hellas.com/'>TWI Hellas</a>, <a  href='https://www.airbus.com/en'>Airbus</a>, <a  href='https://m3systems.eu/en/m3-systems-belgium/'>M3 Systems Belgium</a>, <a  href='https://www.simavi.ro/'>Software
                Imagination & Vision (SIMAVI)</a>, <a  href='https://miontechnologies.com/our-products/'>MION Technologies</a>, <a  href='https://www.f-in.gr/'>Future Intelligence</a>, <a  href='https://www.enac.fr/en'>Ecole Nationale de
                l'Aviation Civile</a>, <a  href='https://www.upc.edu/en'>Universitat Politecnica de Catalunya</a>, <a  href='https://practin.com/'>Practin</a>, <a  href='https://www.johanniter.at/'>Johanniter
                Research and Innovation Center (JOAFG)</a>,  <a  href='https://www.isemi.sk/en_GB/'>ISEM Institute</a>, <a  href='https://www.interoptics.gr/index.php/el/'>INTEROPTICS</a>, <a  href='http://www.epsilon.gr/group/companies/epsilon-malta'>Epsilon Malta</a>, <a  href='https://ispc.gencat.cat/en/inici/index.html'>Institut de Seguretat Publica de Catalunya</a>,  <a  href='https://kemea.gr/en/'>KEMEA</a>,   <a  href='https://cmsa.am/'>Crisis Management State Academy</a>) 
                from 9 countries, gathered for its <a href='https://pantheon-project.eu/The_PANTHEON_project_kick-off_meeting'>kick-off meeting</a> in Athens, Greece. Ready to join forces for a three-
                year collaboration, the partners were committed to strengthening Community Disaster
                Resilience with the crucial contribution of a Smart City Digital Twin platform for Disaster Risk
                Management operations.</p>
                <p>Now, the time has come to acknowledge a full year under the sun for PANTHEON. Thus, the
                3rd General Assembly meeting for Month 12 took place in Malta on January 25-26 and was
                hosted by our partner, Epsilon Malta. This meeting focused on multiple aspects of the <a href='https://research-and-innovation.ec.europa.eu/funding/funding-opportunities/funding-programmes-and-open-calls/horizon-europe_en'>Horizon
                Europe</a> project, including developments over the first year, accomplished, ongoing and
                prospective tasks and presentation of the achieved progress in the last year as well as planning
                for the next one. A highlight of the meeting included an interesting presentation by our external
                guest, <a href='https://www.linkedin.com/in/gmkaragiannis/?originalSubdomain=uk'>Dr. George Karagiannis</a>, PhD, a recognised emergency, crisis and risk management senior
                executive with more than ten years of experience across Europe. Additionally, productive
                workshops on technical matters and planning of communal dissemination and communication
                efforts were conducted.</p>
                <p>Last but not least, the agenda included a dedicated slot for our first User Advisory Board (UAB)
                meeting. Its members, who come from diverse backgrounds in Civil Protection and Disaster
                resilience, convened with the project partners, offering their valuable expert opinions on raised
                questions and proposed ideas regarding the PANTHEON future developments.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={pantheon4}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={pantheon5}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
