import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import pantheon2 from "../../logos/photos/pantheon2.jpg"
import pantheon3 from "../../logos/photos/pantheon3.jpg"

export default function Exhibition() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON's debut exhibition: DEFEA 2023
                 </h3>
             <p>For the first time, the PANTHEON project had the opportunity to spread the word about its
                mission to build robust infrastructure for optimising Disaster Risk Management operations and
                strengthening Community Disaster Resilience. During the three-day <a href='https://defea.gr/'>International Defence
                Exhibition Athens 2023</a> (DEFEA) (May 9-11), the PANTHEON project was presented at the
                exhibition stand of our project partner, the <a href='https://kemea.gr/en/'>Center for Security Studies</a> (KEMEA). One of the
                screens at the stand was dedicated to PANTHEON, showing a short presentation of the current
                challenges in Disaster Resilience, the project technologies, its overall mission, as well as the
                extensive consortium.</p>
                <p>DEFEA 2023 hosted 346 exhibitors from 28 countries and accepted 23.413 visitors from 91
                countries. Additionally, this year, the European Commission also participated in the event and
                presented actions regarding the European Defence Fund supporting research and development
                programmes. The event will resume in 2025, with its third edition between May 6-8.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={pantheon2}
                  style={{borderRadius:'10px',width:'200px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={pantheon3}
                  style={{borderRadius:'10px',width:'200px', marginLeft:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
