import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import pantheon8 from "../../logos/photos/pantheon8.jpg"
import pantheon7 from "../../logos/photos/pantheon7.jpg"
import pantheon9 from "../../logos/photos/viennasecurity.jpg"


export default function Vienna() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON project at the Vienna Security Festival 2023
                 </h3>
                <p>
                From October 25th to 26th, security was the point of reference for the city of Vienna on Rathausplatz, 
                the square in front of the town hall. There, the largest security exhibition, 
                Wiener Sicherheetfest (the Vienna Security Festival), happens yearly, encompassing an 
                informative programme for visitors of all ages. The “<a href='https://diehelferwiens.wien.gv.at/'>Die Helfer Wiens</a>” (Vienna’s helpers) 
                present themselves and showcase their mission along with Vienna’s aid, 
                other emergency response organisations and other departments of the City of Vienna.</p>
                <p>In the context of the event, our consortium partner, the <a href='https://www.johanniter.at/'>Johanniter Research and Innovation
                Center</a>, had a large stand presenting its research projects, including our PANTHEON initiative.
                The project information was projected on a video screen, showcased on a roll-up, and spread to
                the general public through brochures. The overall experience was great, and the stand had
                many visitors interested in talking about our efforts towards enhancing Disaster Resilience
                Management for communities around Europe.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={pantheon8}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={pantheon9}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={pantheon7}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
