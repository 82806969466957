import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import img1 from "../../logos/photos/CERIS_event_cover.jpg"
import img2 from "../../logos/photos/CERIS_event.jpg"





export default function CERIS() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON travels to Brussels for the CERIS event 2024
                                 </h3>
                <p>
                This year, the <a href='https://home-affairs.ec.europa.eu/whats-new/events/ceris-annual-event-2024-disaster-resilient-societies-local-international-cooperation-2024-06-05_en'>CERIS</a> event lasted three days (June 5-7) and centred on building disaster-
resilient societies in Europe. It covered various topics, including networking initiatives, disaster
risk reduction policies, research and capacity-building projects, and improving societal resilience
and technology for responders. The event featured participation from over 25 EU projects and
included more than 40 speakers from different sectors.
</p>
<p>
During CERIS 2024, our PANTHEON
project was present! One of our project
partners, <a href='https://kemea.gr/en/'>the Centre for Security Studies
(KEMEA)</a>, represented it there, specifically
researcher John Tsaloukidis. Thankfully,
there were productive discussions with
attendees, and the <a href='https://pantheon-project.eu/outcomes#material'>PANTHEON leaflet </a>
 was distributed to the interestees who
wanted to discover more about our
mission, research work and technologies.
Overall, the CERIS annual event is an
excellent platform for networking, sharing
developments and exchanging ideas
between projects and stakeholders.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={img2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />

              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
