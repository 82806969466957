import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import kickoff from "../../logos/photos/pantheon1.jpg"

import { useNavigate } from 'react-router-dom';

export default function Kickoff() {
 
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                The PANTHEON project kick-off meeting
             </h3>
             <p>Between 18-19 January 2023, 17 partners from 9 countries came together to commence a 3-
                year collaboration to enhance Community Disaster Resilience with the help of a Smart City
                Digital Twin platform for Disaster Risk Management operations. Athens was set to be the
                location of the kick-off meeting for the <a href='https://research-and-innovation.ec.europa.eu/funding/funding-opportunities/funding-programmes-and-open-calls/horizon-europe_en'>Horizon Europe</a> project since <a href='https://www.twi-hellas.com/'>TWI Hellas</a> is responsible
                for the project coordination and administrative management.</p>
              <p>The totality of the members of the consortium (<a  href='https://www.airbus.com/en'>Airbus</a>, <a  href='https://m3systems.eu/en/m3-systems-belgium/'>M3 Systems Belgium</a>, <a  href='https://www.simavi.ro/'>Software
                Imagination & Vision (SIMAVI)</a>, <a  href='https://miontechnologies.com/our-products/'>MION Technologies</a>, <a  href='https://www.f-in.gr/'>Future Intelligence</a>, <a  href='https://www.enac.fr/en'>Ecole Nationale de
                l'Aviation Civile</a>, <a  href='https://www.upc.edu/en'>Universitat Politecnica de Catalunya</a>, <a  href='https://practin.com/'>Practin</a>, <a  href='https://www.isemi.sk/en_GB/'>ISEM Institute</a>, <a  href='https://www.interoptics.gr/index.php/el/'>INTEROPTICS</a>,
                <a  href='https://www.johanniter.at/'>JOAFG</a>, <a  href='http://www.epsilon.gr/group/companies/epsilon-malta'>Epsilon Malta</a>, <a  href='https://ispc.gencat.cat/en/inici/index.html'>Institut de Seguretat Publica de Catalunya</a>, <a  href='https://www.astynomia.gr/?lang=en'>Hellenic Police</a>,
                 <a  href='https://kemea.gr/en/'>KEMEA</a>,
                <a  href='https://cmsa.am/'>Crisis Management State Academy</a>) united for the two-day event in order to present their work
                and future contributions in the project. The project objectives and expected results were also
                discussed, and a schedule for the upcoming technical advancements was set.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={kickoff}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
