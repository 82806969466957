import React from "react";
import img from "./10.png"

export default function FeatureImg() {
  return (
    <>
      <div id="plan" className="feature-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <h3 style={{color:'#ef8948'}}>Project Objectives
</h3>
               
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5 >PANTHEON Disaster - Resilient Communities Model
</h5>
                  <p className="mb-0">
                  - Assemble and assess community resilience indicators on a national and local scale

                  </p>
                  <p className="mb-0">
                  - Take into account the different cultures, vulnerable groups, various kinds of dangers and the five community disaster resilience domains (social, economic, institutional, physical and natural).

                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Decentralised and Participatory Governance & Tools
</h5>
                  <p className="mb-0">
                    - Promote cooperative learning between the research and local communities
                  </p>
                  <p className="mb-0">
                    - Enhance the collection of targeted data around risks and multi-variable disastrous events
                  </p>
                  <p className="mb-0">
                    - Develop a framework and accompanying tools, adaptable and adoptable by local communities
                  </p>
               
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Intelligence, CM-SCDT Processes and Optimal Disaster Risk Management Operations
</h5>
                  <p className="mb-0">
                    - Employ earth observation data, Smart City IoT,  insitu, community-generated data and AI technologies. 
                  </p>
                  <p className="mb-0">
                    - Focus on Big Data processing for data cleaning, organising and restructuring; design appropriate and controlled data flow.
                  </p>
                  <p className="mb-0">
                    - Improve the accuracy of the DT by adding AI/ML with a certain amount of data for training purposes.	
                  </p>
                </div>
              </div>
              
              
           
            </div>
            <div className="col-lg-4">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img style={{borderRadius:'20px'}}
                  alt="placeholder"
                  src="assets/img/fire.jpg"
                  className="img-center img-fluid"
                />
                  <img style={{borderRadius:'20px'}}
                  alt="placeholder"
                  src="assets/img/tsunami.jpg"
                  className="img-center img-fluid"
                />
                <img style={{borderRadius:'20px'}}
                  alt="placeholder"
                  src="assets/img/earthquake.jpg"
                  className="img-center img-fluid"
                />
                 <img style={{borderRadius:'20px'}}
                  alt="placeholder"
                  src="assets/img/flood.jpg"
                  className="img-center img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Development of a Smart City Digital Twin (SCDT) for Community Disaster Risk Management (DRM)
</h5>
                  <p className="mb-0">
                  - Employ relevant emerging technologies for data collection and processing while using intelligence and self-learning features based on community traits, strengths and weaknesses
                  </p>
                  <p className="mb-0">
                  - Increase reliability with almost real-time data critical to improving disaster management
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Development of a synthetic Environment</h5>
                  <p className="mb-0">
                    - Build a synthetic environment for training purposes by incorporating adequate historical weather and environmental factors for optimal asset assessment and operators’ training.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Data Delivery Scheme and Remote Sensing for Community-based SCDT</h5>
                  <p className="mb-0">
                    - The PANTHEON digital twin-component will utilise community data to generate simulation models that can offer decision-makers predictive information conducive to effective disaster management.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Interactive User Interface for a Holistic Simulation Environment
</h5>
                  <p className="mb-0">
                    - Offer more user-interaction modes with a higher level of control and user-friendliness 
                  </p>
                  <p className="mb-0">
                    - Develop and test a robust system that can handle larger amounts of data
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
