import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import img1 from "../../logos/photos/1000084730.jpg"
import img2 from "../../logos/photos/1000086840.jpg"

import icon from '../../pages/pdficon2.png'
import MyPDF1 from './ISCRAM2024.pdf'




export default function ISCRAM() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                Our PANTHEON Project at ISCRAM 2024
                                 </h3>
                <p>
                From the 25th to the 29th of May 2024, the <a href='https://iscram.org/'>ISCRAM conference</a> was in Münster, Germany. The
event follows a pracademic approach and was co-hosted by the <a href='https://crisismanagement.ercis.org/'>ERCIS Competence Center for
Crisis Management at the University of Münster</a> and the <a href='https://www.idf.nrw.de/'>State Fire Service Institute North
Rhine-Westphalia</a>. This year, the central subject of the conference was “Embracing the Crisis
Management Lifecycle”, which focuses on a comprehensive and integrated view of crisis
management rather than just considering individual phases of the crisis management lifecycle.
The aim was to overcome isolated considerations of crisis management lifecycle phases and
showcase how vital well-structured and clearly defined roles, tasks and processes are to
supporting communication and collaboration between involved actors.
</p>
<p>
Representing our PANTHEON project, Lola Vallès and Kiril Shtefchyk Tatarchuck from <a href='https://ispc.gencat.cat/en/inici/index.html'>the
Institute for Public Security of Catalonia (ISPC)</a> participated in the international conference with
a poster presentation. Thankfully, it attracted the attention of disaster management experts and
the public since it addressed the analysis of how different disastrous events can affect
communities with few resources. Our consortium has put together socio-demographic indicators
that are integral to calculating the vulnerability and capacity of different regions to identify
vulnerable groups. Thanks to the above study, which took place during the 1st year of the
project, the focus shifted to the pre-disaster phase (disaster planning and training tools) as well
as fueling the development of the respective digital twins of the respective components.
Additionally, our project’s choice of heat waves interested many attendees since it’s one of the
most relevant disasters at present. Subsequently, discussions on climate change and climate
awareness also took place.</p>
<p>Overall, the conference was interesting and productive. It provided valuable insights and
sparked important debates on current disaster management approaches.</p>
<p>For extra information, check the PANTHEON poster below.</p>
<a style={{color:'#292929'}}id='newsletter' href={MyPDF1} download="ISCRAM2024.pdf">Poster <img style={{width:'22px',top:'8px',position:'relative'}} src={icon}></img></a>

              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
            
                <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={img2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
